*, *::before, *::after {
    box-sizing: border-box;
  }
  
  * {
    margin: 0;
    padding: 0;
    font: inherit; /* Get rid of all font sizes and heights */
  }
  
  html {
    color-scheme: light; /* Default dark */
    hanging-punctuation: first last; /* Not supported in Chrome */
  }
  
  body {
    min-height: 100vh;
  }
  
  /* Media responsive */
  img, picture, svg, video {
    display: block;
    max-width: 100%;
  }
  
  h1, h2, h3, h4, h5, h6 {
    text-wrap: balance;
  }
  
  p {
    max-width: 75ch;
    text-wrap: pretty; /* Prevents orphans on lines */
  }
  
  @media (prefers-reduced-motion: no-preference) {
    :has(:target) {
      scroll-behavior: smooth;
      scroll-padding-top: 3rem;
    }
  }