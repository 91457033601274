:root {
  --page-margin--l: 80px;
  --page-margin--m: 65px;
  --page-margin--s: 20px;

  --btn-color: #3A3cE6;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica Neue/Helvetica Neue LT 25 Ultra Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica Neue/Helvetica Neue LT 26 Ultra Light Italic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica Neue/Helvetica Neue LT 45 Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica Neue/Helvetica Neue LT 46 Light Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica Neue/Helvetica Neue LT 55 Roman.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica Neue/Helvetica Neue LT 56 Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica Neue/Helvetica Neue LT 75 Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica Neue/Helvetica Neue LT 76 Bold Italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Atyp BL';
  src: url('./fonts/Atyp BL Font Family/AtypBLText-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Atyp BL';
  src: url('./fonts/Atyp BL Font Family/AtypBLText-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Atyp BL';
  src: url('./fonts/Atyp BL Font Family/AtypBLText-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Atyp BL';
  src: url('./fonts/Atyp BL Font Family/AtypBLText-Italic.otf') format('opentype');
  font-weight: 400 700;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'Atyp BL', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root>div {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 1rem 3.2rem;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  position: sticky;
  z-index: 10;
}


h1, h2, h3 {
  font-family: "Atyp BL", sans-serif;
  background: linear-gradient(to right, #1A0046, #3A3CE6);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  line-height: 1.2;
}

h4, h5 {
  color: #1A0046;
}


h1 {
  font-weight: 700;
  font-size: 62px;
}

h2 {
  font-size: 48px;
  font-weight: 600;
}

h3 {
  font-size: 48px;
  font-weight: 600;
}

h4 {
  font-size: 32px;
}

h5 {
  font-family: 'Helvetica Neue';
  font-size: 24px;
  font-weight: 600;
}

.team-member h3 {
  color: #1A0046;
  background: unset; 
  -webkit-text-fill-color: unset;
}

p {
  font-family: 'Helvetica Neue';
  color: #1E293B;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.6; 
}

.grid-theme--light h2 {
  font-weight: 400;
}

/* Menu */
.menu-active-link {
  color: #616EF3;
  font-weight: 600;
}

.menu-inactive-link {
  font-weight: 600;
}

/* page */ 

.page-container {
  margin: 0 var(--page-margin--l);
  min-height: calc(100vh - 100px - 437px - 4px);
}

.divider {
  width: 100%;
  max-width: 1440px;
  margin-inline: auto;
  background-color: rgb(211, 228, 255);
  height: 2px;

}

.section-background--grey {
  background-color: rgb(246, 246, 246);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 6rem 0px;
  margin-inline: calc( var(--page-margin--l) * -1);
}

.row-container {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.section-joinus {
  align-items: left;
  margin-top: 6em;
}

.section-joinus p {
  text-align: left;
}

.section-joinus .row-container--right{
  max-width: 576px;
  width: 100%;
  height: auto;
}
.section-joinus .row-container--right img{
  width: 100%;
  height: auto;
}


.row-container--left {
  max-width: 556px;
  text-align: left;
}

.row-container--left h2{
  margin-bottom: 32px;
}

.bold {
  font-weight: 700;
}
/* sections */ 

section {
  display: flex; 
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 62px; 
  margin-inline: auto;
  margin-bottom: 6em;
  margin-top: 1em;
  max-width: 1440px;
}

.promoting-section {
  display: flex; 
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 62px; 
  margin-inline: auto;
  margin-bottom: 6em;
  margin-top: 6em;
  max-width: 1440px;
}

.elderly-section {
  display: flex;
  gap: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.journey-section {
  align-items: center;
  margin-top: 4em;
}

.simple-section {
  text-align: left;
  place-items: start;
  justify-content: space-between;
  margin-top: 36px;
  margin-bottom: 120px;
}

.simple-section--left {
  max-width: 450px;
}

.simple-section--right {
  width: 710px
}

.goals-section {
  justify-content: left;
  margin: 6em 0;
}

.goals {
  margin-top: 30px;
}
.goals p{
  margin-bottom: 30px;
  padding: 20px;
}


.grid {
  display: grid;
  grid-template-columns: 370px 1fr;
  place-items: start;
  text-align: left;
  gap: 100px;
  margin-top: 36px;
  margin-bottom: 120px;
}
/* list type */ 

.icon-list ul {
  list-style-type: none;
  text-align: left;
  padding-left: 45px;
  position: relative;
}

.icon-list li {
  padding-left: 8px;
}

.icon-list li:not(:last-child) {
  margin-bottom: 16px;
}


.icon-list li div {
  max-width: 523px;
}


.icon-list li div h5 {
  margin-bottom: 8px;
}


.icon-list li div p{
  font-size: 20px;
}

.icon-list li::before {
  content: "";
  position: absolute;
  background: url(./images/icons/solution_icon.svg);
  width: 45px;
  background-size: cover;
  height: 45px;
  left: 0;
}


.grid .icon-list ul {
  padding-left: 55px;
}

.grid .icon-list li::before {
  left: 0;
}

.grid .icon-list li {
  padding-left: 0;
}

.grid .icon-list li div p {
  font-size: 32px;
}


.grid .icon-list li div h5 {
  font-size: 32px;
}


.grid .icon-list li div {
  max-width: 100%;
}



/* Button */
.cta {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 60px;
  gap: 10px;
  font-size: 18x;
  
  font-weight: 600;;

  width: 321px;
  height: 64px;
  
  /* brand 3 */
  border: none;
  background: var(--btn-color);
  border-radius: 100px;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.5px;
  font-weight: 600;
  transition: 0.3s background-color;
  text-decoration: none;
}

.cta:hover {
  background-color: #2D3082;
}

/* Cards */ 

.card-container {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  column-gap: 3rem;
  row-gap: 2rem;
  padding: 0 16px;
}

.card {
  max-width: 375px;
  min-width: 300px;
  min-height: 400px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  color: rgb(51, 51, 51);
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding-bottom: 48px;
}

.card .card-image {
  background-color: rgba(58, 60, 230, 0.1);
  padding: 2rem;
  border-radius: 25px;
  display: flex;
  height: 285px;
  border: 10px solid rgb(255, 255, 255);
}
.card .card-image img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.card-textblock {
  font-size: 16px;
  padding: 0 16px;
}

.card-textblock h4 {
  color: #1A0046;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 16px;
}

.card-textblock .card-text {
  font-size: 24px;
}

.highlight {
  font-weight: 700;
  color: #2176FF;
}

.researchPartners .team-member img {
  max-width: 350px;
}

@media only screen and (max-width: 1024px){
  body {
    /*padding-top: 93px;*/
  }
  
  h1 {
    font-size: 30px;
    line-height: 117%;
  }

  h2 {
    font-size: 24px;
    line-height: unset;
  }

  h4, h5 {
    font-size: 16px;
    line-height: 160%;
  }

  h5 {
    font-weight: 600;
  }

  p {
    font-size: 16px;
  }

  .cta {
  font-size: 12px;
  }

  .page-container {
    margin: 0 var(--page-margin--m);
    min-height: calc(100vh - 385px); /* possible issue */
  }

  .section-background--grey {
    padding: 36px 0;
    margin-inline: calc(var(--page-margin--m) * -1);
  }

  .card-textblock h4 {
    font-size: 24px;
  }

  .card-textblock .card-text {
    font-size: 16px;
  }

  .icon-list li::before {
    width: 30px;
    height: 30px;
  }

  .icon-list li {
    padding-left: 0px;
  }


  .icon-list li div p{
    font-size: 16px;
  }


  section {
    gap: 38px; 
  }

  .elderly-section {
    display: flex;
    gap: 0px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .card-container {
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    column-gap: 1rem;
    row-gap: 2rem;
    padding: 0 16px;
  }
  
  .cta {
    padding: 12.2749px 36.8247px;
    gap: 6.14px;

    width: 200px;
    height: auto;

    /* brand 3 */
    border-radius: 61.3744px;

    font-size: 12px;
  }

  .grid {
    grid-template-columns: 0.5fr 1fr;
    gap: 50px;
    margin-bottom: 48px;
  }

  .grid h2 {
    line-height: 1.23;
  }
  .grid .icon-list li div p {
    font-size: 16px;
  }
  
  .grid .icon-list li div h5 {
    font-size: 16px;
  }
  .grid .icon-list ul {
    padding-left: 35px;
  }

  .goals-section {
    margin: 18px 0;
  }  
  section {
    margin: 0 0 3em 0;
  }

  .row-container--left h2 {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 767px){
  body {
    /*padding-top: 67px;*/
  }
  h1 {
    font-size: 30px;
    line-height: 117%;
  }

  h2 {
    line-height: unset;
  }

  .page-container {
    margin: 0 var(--page-margin--s);
    min-height: calc(100vh - 352px);
  }

  .section-background--grey {
    padding: 36px 0;
    margin-inline: calc(var(--page-margin--s) * -1);
  }

  .icon-list li::before {
    width: 24px;
    height: 24px;
  }

  .elderly-section {
    gap: 24px;
    flex-direction: column;
  }

  section {
    gap: 24px; 
  }

  .row-container {
    flex-direction: column;
  }

  .section-joinus{
    gap: 16px;
    flex-direction: column;
    flex-flow: column-reverse;
  }

  .section-joinus .row-container--right{
    place-items: center;
  }

  .section-joinus .row-container--left {
    text-align: center;
  }

  .section-joinus .row-container--left h2{
    margin-bottom: 16px;
  }

  /* Button */

  .cta {
    /* Button */
    padding: 10.3987px 31.1962px;
    gap: 5.2px;

    width: 200px;
    height: auto;
    border-radius: 51.9936px;
  }

  .grid {
    grid-template-columns: 1fr;
    gap: 24px;
    margin-bottom: 48px;
    margin-top: 48px;
  }

  .grid h2 {
    line-height: 1.3;
  }

  .grid .icon-list li::before {
    left: 0px;
  }
  .grid .icon-list li {
    padding-left: 30px;
  }

  .grid .icon-list ul {
    padding-left: 0px;
  }
  .goals-section {
    margin: 18px 0;
  }

  section {
    margin: 0 0 3em 0;
  }

}
